.otp-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
  
.otp-container > div > input{
    border: 1px solid #ccc !important;
    width: 2.5rem !important;
    height: 2.5rem;
}